import React from 'react';

const Rules = () => {
    return (
        <div style={{
            display: "flex",
            height: "100%",
            marginLeft: 20,
            marginRight: 20,
            justifyContent: "center",
            flexGrow: 2
        }}>
        </div>
    );
};

export default Rules;